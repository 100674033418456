export default {
  async update (Vue, id, formData) {
    await Vue.$offline.fichero.updateSync({
      idfichero: id,
      idtfichero: formData.idtfichero,
      observaciones: formData.observaciones,
      carpeta: formData.carpeta,
    })
  },
}
